import httpClient from './httpClient';

const INDIVIDUAL_END_POINT = '/individual_taxes';
const APPLICABLE_END_POINT = '/applicable_taxes';

const listIndividualTaxes = () => httpClient.get(INDIVIDUAL_END_POINT);
const listApplicableTaxes = () => httpClient.get(APPLICABLE_END_POINT);

const createIndividualTax = (individual_tax) => httpClient.post(INDIVIDUAL_END_POINT, { individual_tax });
const createApplicableTax = (applicable_tax) => httpClient.post(APPLICABLE_END_POINT, { applicable_tax });

const getIndividualTax = (id) => httpClient.get(INDIVIDUAL_END_POINT + '/' + id);
const getApplicableTax = (id) => httpClient.get(APPLICABLE_END_POINT + '/' + id);

const updateIndividualTax = (id, individual_tax) => httpClient.put(INDIVIDUAL_END_POINT + '/' + id, { individual_tax });
const updateApplicableTax = (id, applicable_tax) => httpClient.put(APPLICABLE_END_POINT + '/' + id, { applicable_tax });

const deleteIndividualTax = (id) => httpClient.delete(INDIVIDUAL_END_POINT + '/' + id);
const deleteApplicableTax = (id) => httpClient.delete(APPLICABLE_END_POINT + '/' + id);

export{
    listIndividualTaxes,
    listApplicableTaxes,
    createIndividualTax,
    createApplicableTax,
    getIndividualTax,
    getApplicableTax,
    updateIndividualTax,
    updateApplicableTax,
    deleteIndividualTax,
    deleteApplicableTax
}