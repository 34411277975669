<template>
<div>
    <inline-form-row :label="label('Address')" type="text" v-model="value.address" :errors="errors['address']" helpText="Address"/>
    <inline-form-row :label="label('Postal Code')" type="text" v-model="value.postal_code" :errors="errors['postal_code']" helpText="Postal Code"/>
    <inline-form-row :label="label('City')" type="text" v-model="value.city" :errors="errors['city']" helpText="City"/>
    <inline-form-row :label="label('State')" type="text" v-model="value.state" :errors="errors['state']" helpText="State"/>
    <inline-form-row :label="label('Country')" type="text" v-model="value.country" :errors="errors['country']" helpText="Country"/>
</div>
</template>
<script>

import InlineFormRow from '@/components/InlineFormRow'

export default {
    props: {
        value: Object,
        label_prefix: String,
        errors: {
            type: Array,
            default() {
                return []
            }
        }
    },
    methods: {
        label: function(base) {
            if(this.label_prefix) {
                return this.label_prefix + ' ' + base
            }
            return base
        }
    },
    watch: {
        value() {
            this.$emit('input', this.value);
        }
    },
    components: {
        InlineFormRow
    }
}
</script>