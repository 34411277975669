<template>
<div>
    <inline-form-row label="Name" type="text" v-model="project.name" :errors="errors['name']" />
    <inline-form-row label="Purchase Order" type="text" v-model="project.purchase_order" :errors="errors['purchase_order']" />
    <BMSRole :roles="project.roles" :project="project"/>
    <div class="text-right">
      <b-button class="m-1" @click="onSaveProject(project)">Save Project</b-button>
      <b-button class="m-1" @click="onDeleteProject(project)">Delete Project</b-button>
    </div>
</div>
</template>
<script>

import { createProject, updateProject, deleteProject } from '@/api/projects.api'
import InlineFormRow from '@/components/InlineFormRow'
import BMSRole from '@/components/BMSRole'
import crudMixin from '@/api/crud'
import confirmationModal from '@/api/confirmation'

export default {
    mixins: [crudMixin, confirmationModal],
    props : {
        project : Object,
        clientid : [String, Number]
    },
    data() {
        return {
            roleErrors: []
        }
    },
    methods : {
        sendToProject(project){
            window.open(`#/projects/${project.id}`)
        },
        onSaveProject: function(project) {
            // Create a payload so we can manipulate the fields
            var payload = JSON.parse(JSON.stringify(project))
            payload.client = { id: this.clientid }
            delete payload.roles

            if (project.id) {
                var apiCall = updateProject(project.id, payload)
            } else {
                apiCall = createProject(payload)
            }
            this.saveObject(
                apiCall,
                (res) => {
                    var location = res.data.location
                    project.id = parseInt(location.substring(location.lastIndexOf('/') + 1))
                }
            )
        },
        onDeleteProject: function(project) {
            if (project.id) {
                this.projectId = project.id
                // confirmModal(message, okTitle, cancelTitle)
                this.confirmModal().then((result) => {
                if (result){
                    this.deleteObject(
                        deleteProject(this.projectId),
                        () => {
                            this.$emit('deleteProject')
                        }
                    )
                }
            })
        }
        }
    },
    components: {
        InlineFormRow,
        BMSRole
    }
}
</script>