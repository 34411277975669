<template>

  <b-card no-body>
    <b-tabs card>
      <b-tab title="Contact">
        <b-form>
          <span v-if="error" class="error">{{ error }}</span>
          <inline-form-row label="Company Name" type="text" :disabled="!this.isNew" v-model="client.company" :errors="errors['company']" helpText="Client full name - for billing purposes"/>
          <inline-form-row label="Contact" type="text" v-model="client.contact" :errors="errors['contact'] " helpText="Billing contact"/>
          <inline-form-row label="Email" type="text" v-model="client.email" :errors="errors['email']" helpText="Billing email address"/>
          <inline-form-row label="Phone" type="text" v-model="client.phone" :errors="errors['phone']" helpText="Phone number (no specific format)"/>
          <inline-form-row label="Vendor Number" type="text" v-model="client.vendor_number" :errors="errors['vendor_number']" helpText="Unique vendor number for your business given by the client"/>
          <BMSAddress v-model="client.address" />
        </b-form>
        <div class="text-right">
          <b-button @click="onSaveClient()" variant="primary" class="m-1">
            Save
          </b-button>
          <b-button @click="onDeleteClient" variant="danger" :disabled="isNew" class="m-1">
            Delete
          </b-button>
        </div>
      </b-tab>
      <b-tab title="Projects" :disabled="isNew">
        <b-button class="float-right" variant="success" @click.prevent="onAddProject()">
          <b-icon icon="plus-circle" />Add Project
        </b-button>
        <br />
        <br />
        <div class="accordion" role="tablist">
          <b-card
              v-for="(project, index) in projects"
              :title="project.name"
              :key="index"
              no-body
              class="mb-1"
            >
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle="'accordion-' + index" variant="info">Project: {{ project.name }}</b-button>
            </b-card-header>
            <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <BMSProject :project="project" :clientid="id" @deleteProject="onDeleteProject(project, index)" />
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-tab>
      <b-tab title="Timesheets" :disabled="isNew">
        <TimesheetTable :items="timesheets" :mapping="mapping" />
      </b-tab>
      <b-tab title="Invoices" :disabled="isNew">
          <InvoiceTable :items="invoices" />
      </b-tab>
      <b-tab title="Payment" :disabled="isNew">
        <inline-form-row label="Bank Account" _type="b-form-select" :options="bank_accounts" :disabled="this.isNew" v-model="client.accounts_payable" helpText="Select the bank account"/>
        <b-form-row>
          <b-col class="col-sm-3"><label class="col-form-label">Tax Rule</label></b-col>
          <b-col class="col-sm-6">
            <b-form-select v-model="client.applicable_tax" :options="tax_options"  />
          </b-col>
          <b-button-group v-b-tooltip.hover.right title="This will set default invoice tax option">
              <b-icon icon="question-circle" scale="1" variant="primary" class="mt-1"></b-icon>
          </b-button-group>
        </b-form-row>
        <div class="text-right">
          <b-button @click="onSaveClient()" variant="primary" class="m-1">
            Save
          </b-button>
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { getTimesheets, getTimesheetInvoiceMapping } from '@/api/projects.api'
import { getClient, createClient, updateClient, deleteClient, getProjects, getInvoices } from '@/api/clients.api'
import { getBankAccounts } from '@/api/settings.api'
import { getConsultingRoles } from '@/api/consulting.api'
import { listApplicableTaxes } from '@/api/taxes.api'

import BMSAddress from '@/components/BMSAddress'
import BMSProject from '@/components/BMSProject'
import InvoiceTable from '@/components/InvoiceTable'
import InlineFormRow from '@/components/InlineFormRow'
import TimesheetTable from '@/components/TimesheetTable'
import crudMixin from '@/api/crud'
import confirmationModal from '@/api/confirmation'

export default {
    mixins: [crudMixin, confirmationModal],
    data() {
        return {
            confirm: '',
            id: this.$route.params.id,
            client: {address:{}},
            projects: [],
            fields: [{
                key: 'name',
                label: 'Name',
                sortable: true,
            }, {
                key: 'purchase_order',
                label: 'Purchase Order',
                sortable: true,
            }, {
                label: 'Action',
                key: 'actions'
            }],
            bank_accounts: [],
            invoices:[],
            mapping: {},
            timesheets: [],
            applicable_taxes: []
        }
    },
    computed: {
        isNew: function() {
            return this.id != parseInt(this.id)
        },
        tax_options: function() {
            return this.applicable_taxes.map((tax) => {
                return {
                    value: tax,
                    text: `${tax.name}`
                }
            })
        },
    },
    beforeMount() {
        this.refresh()
        this.getApplicableTaxes()
    },
    components: {
        InlineFormRow,
        BMSAddress,
        BMSProject,
        InvoiceTable,
        TimesheetTable
    },
    methods: {
        refresh: function() {
            if (!this.isNew) {
                this.getClient();
                this.getProjects();
                this.getBankAccounts();
                this.getInvoices();
            }
        },
        getApplicableTaxes() {
            this.applicable_taxes.splice(0)
            this.call(
                listApplicableTaxes(),
                (res) => {
                    this.applicable_taxes.push(...res.data.applicable_taxes)
                }
            )
        },
        getClient: function() {
            this.call(
                getClient(this.id),
                (response) => {
                    this.client = response.data.client
                }
            )
        },
        getProjects: function() {
            this.call(
                getProjects(this.id),
                (response) => {
                    response.data.projects.forEach(project => {
                        delete project.client;
                        // Inject consulting roles
                        project.roles = []
                        this.call(
                            getConsultingRoles(new URLSearchParams([['project_id', project.id]])),
                            (res) => {
                                res.data.roles.forEach(role => {
                                    delete role.project
                                    project.roles.push(role)
                                })
                            }
                        )
                        this.projects.push(project);
                        // Inject timesheet per project
                        this.getTimesheets(project.id);
                        this.getTimesheetInvoiceMapping(project.id)
                    })                                                     
                }
            )
        },
        getInvoices:function(){
          this.call(
            getInvoices(this.id),
              (res) => {
                this.invoices = res.data.invoices
              }
            )
        },
        getBankAccounts() {
            this.call(
                getBankAccounts(),
                (res) => {
                    this.bank_accounts.splice(0)
                    res.data.bank_accounts.forEach(account => {
                        this.bank_accounts.push({
                            'value': account,
                            'text': `${account.label} - ${account.name} - ${account.currency} (${account.account_number})`
                        });
                    })
                }
            )
        },
        getTimesheets: function(projectId) {
            this.call(
                getTimesheets(projectId),
                (response) => {
                  this.timesheets.splice(0, ...response.data.time);
                }
            )
        },     
        getTimesheetInvoiceMapping: function(projectId) {
            this.call(getTimesheetInvoiceMapping(projectId), (response) => {
                response.data.mapping.forEach((mappingItem) => {
                    const key = mappingItem[0];
                    this.$set(this.mapping, key, mappingItem);
                })
            })
        },
        onDeleteClient: function() {
          // confirmModal(message, okTitle, cancelTitle)
          this.confirmModal().then((result) => {
            if (result){
              this.deleteObject(
                deleteClient(this.id),
                () => {
                    this.$router.push('/clients')
                }
            )
            }
          })
            
        },
        onSaveClient: function() {
            if (this.isNew) {
                var apiCall = createClient(this.client)
            } else {
                apiCall = updateClient(this.id, this.client)
            }
            this.saveObject(apiCall)
        },
        onAddProject: function() {
            this.projects.push({roles: []});
        },
        onDeleteProject: function(project, index) {
            this.projects.splice(index, 1)
        }
    },
    watch: {
        $route(to) {
            this.id = to.params.id
            this.refresh()
        }
    }
}
</script>
<style scoped>
   button[disabled] { 
      pointer-events: none;
      display: none;
    }
</style>